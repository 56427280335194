.color-list-items {
  display: flex;
  flex-wrap: wrap;
}

.color-list-item {
  text-align: center;
  vertical-align: top;
  margin-bottom: 30px;
  display: block;

  &.active {
    text-decoration: none;

    .cli__swatch:after {
      content: 'currently viewing';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;
      line-height: 11px;
      letter-spacing: 0.91px;
      width: 100%;
      padding: 9px 0 8px;
      color: $white;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .cli__swatch {
    position: relative;
    display: block;
    width: 100%;
    transition: box-shadow 150ms ease-out;
    font-size: 0;
    line-height: 0;
  }

  .cli__name {
    margin-top: 7px;
    margin-bottom: 0;
    color: $gray-3;
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
  }
}


@media (min-width: $size-sm) {
  .color-list-items {
    display: block;
    font-size: 0;
  }

  .color-list-item {
    display: inline-block;
    flex: none;
    max-width: none;
    margin: 0 0 26px;

    &:hover {
      .cli__swatch {
        &:before {
          border: 3px solid rgba(0,0,0,0.3);
        }
      }

      .cli__name {
        color: #111;
        text-decoration: none;
      }
    }

    .cli__swatch {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 3px solid transparent;
        transition: border 150ms ease-out;
      }
    }

    .cli__name {
      display: inline-block;
      line-height: 0.8rem;
      color: $gray-5;
      transition: color 150ms ease-out;
    }
  }
}

@media (min-width: 1280px) {
  // 5 cols
  .color-list-item {
    width: 20%;
  }

  .color-list-items--large {
    // 6 cols
    .color-list-item {
      width: 16.66667%;
    }
  }
}
