@include margin-variant(74, 74px);
@include margin-variant(40, 40px);
@include margin-variant(36, 36px);
@include margin-variant(30, 30px);
@include margin-variant(25, 25px);
@include margin-variant(20, 20px);
@include margin-variant(16, 16px);
@include margin-variant(10, 10px);
@include margin-variant(3, 3px);
@include margin-variant(0, 0px);

.centered {
  text-align: center;
}
