// misc base styling specific to Essex theme
html {
  // ... or figure out why there's some horz scroll on ios
  overflow-x: hidden;
}

hr {
  border: 0;
  height: 1px;
  background-color: #E6E6E6;
}

ul {
  margin-top: 2px;
  margin-left: 3px;
}

ul li {
  margin-top: 2px;
  list-style-type: none;
  background: url('../images/bullet.svg') 3px 10px no-repeat;
  background-size: 3px;
  padding-left: 30px;
}

// mobile Select menu styling
@media (max-width: $size-md) {
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    border-color: #ccc;
    border-size: 1px;
    background-color: #fff;
    padding: 8px 12px 7px;
    width: 100%;
    position: relative;

    // TODO: need SVG icon
    background: transparent url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") no-repeat 96% center;
  }
}

.bar {
  width: 60px;
  height: 3px;
  background-color: $brown;
  margin: 30px auto;
}
