.modular-image-features {
  padding: 0 0 40px 0; // FPO
  text-align: center;

  .module-description {
    margin-bottom: 40px;
  }

  .feature-image {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
  }

  .feature-header {
    margin: 26px 0 10px;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    color: $gray-3;
  }

  .btn {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media (min-width: $size-sm) and (max-width: $size-md) {
  .modular-image-features {
    .modular-image-feature:nth-child(3n) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}


@media (min-width: $size-md) {
  .modular-image-features {
    padding: 0 0 80px 0;

    .module-description {
      margin-top: 10px;
      margin-bottom: 60px;
    }
  }
}
