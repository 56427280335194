#footer {
  padding-top: 40px;
  color: $gray-7;
  background-color: transparent;

  // the top border
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    width: calc(100% - 40px);
    height: 1px;
    background-color: #E6E6E6;
  }

  h4 {
    margin-bottom: 12px;
  }

  ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  .column {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .footer-legal {
    margin: 40px 0;
    font-size: 13px;

    & > * {
      display: block;
    }

    a {
      color: $off-white;
    }
  }
}

.footer__email-signup {
  $inputWidth: 288px;

  // make submit relative to this
  &-fields {
    position: relative;
    margin-top: 12px;
    max-width: 288px;
  }

  &-response {
    padding-top: 10px;
  }

  input[type='email'] {
    padding: 6px 0 6px 18px;
    border-radius: 20px;
    outline: 0;
    border: 2px solid #ccc;
    width: $inputWidth;
    line-height: 1;
    height: 40px;
    max-width: 100%;
    -webkit-appearance: none;
  }

  // place submit btn "within" email input
  input[type='submit'] {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 0;
    position: absolute;
    right: 8px;
    top: 8px;
    background-color: $brown;
    background-image: url('../images/icon-arrow-right-white.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 16px;
    line-height: 19px;
    color: #777;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    font-size: 16px;
    line-height: 19px;
    color: #777;
  }
  :-ms-input-placeholder { /* IE 10+ */
    font-size: 16px;
    line-height: 19px;
    color: #777;
  }
  :-moz-placeholder { /* Firefox 18- */
    font-size: 16px;
    line-height: 19px;
    color: #777;
  }
}

@media (min-width: $size-md) {
  #footer {
    padding-top: 76px;

    &:before {
      left: 40px;
      right: 40px;
      width: calc(100% - 80px);
    }

    .footer-legal {
      text-align: center;
      margin: 60px 0;

      & > * {
        margin: 0 14px;
        display: inline-block;
      }
    }
  }
}
