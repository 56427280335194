.modular-contact-form {
  padding: 40px 20px 0;
  text-align: center;

  .asterisk {
    font-size: 21px;
    color: $brown;
  }

  .cf__form {
    text-align: left;

    &-response {
      &.red {
        a {
          color: red;
        }
      }
    }

    .form-element:first-child {
      margin-top: 20px;
    }

    .form-checkbox-label {
      line-height: 1rem;
    }

    input[type='submit'] {
      width: 100%;
      border-radius: 20px;
      color: $gray-5;
      text-align: center;
      border-color: $brown;
      margin: 16px 0 24px;

      &:hover {
        color: $white;
        background-color: $brown;
      }
    }
  }
}

@media (min-width: $size-md) {
  .modular-contact-form {
    padding: 80px 0;

    .cf__body {
      margin: 0 auto;
    }

    .cf__form {
      // fake the grid
      max-width: 50%;
      margin: 0 auto;

      .form-checkbox-label {
        line-height: 30px;
      }
    }
  }
}
