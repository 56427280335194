#header {
  width: 100%;

  padding: 0;
  font-size: 14px;

  .navbar-section {
    height: $header-height-small;
  }
}


@media (min-width: $size-md) {
  #header {
    position: relative;
    padding: 0 40px;
    height: $header-height-large;

    .navbar-section {
      height: $header-height-large;
    }
  }
}


.login-status-wrapper {
  white-space: nowrap;

  .icon {
    font-size: 120%;
  }
}
