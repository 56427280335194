.gallery {
  text-align: center;

  .title {
  }

  .images {
    margin-top: 30px;
    // like a columns container
    margin-left: -10px;
    margin-right: -10px;
  }

  .image-hover {
    display: none;
  }

  .bricklayer-column-sizer {
    width: 50%;
  }

  .bricklayer-column {
    padding: 0;
    // will be children of .images
    margin: 15px 10px;
  }

  .image {
    display: none;
    margin-bottom: 30px;

    & > img {
      display: block;
      width: 100%;
      height: auto;
    }
  }


  .bricklayer-column {
    .image {
      display: block;
    }
  }
}

// lightgallery customizations
.lg-outer .lg-thumb-item {
  border-radius: 0;
  border-width: 3px;
  border-color: transparent;

  &.active {
    border-color: $white;
  }
}

.lg-toolbar {
  // background-color: rgba(0, 0, 0, 0.25);
  background-color: transparent;
}

.lg-outer .lg-image {
  // TODO: prob need to talk to Gabe and change this
  max-height: 67%;
}

.lg-sub-html {
  // background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
}


@media (min-width: $size-md) {
  .gallery {
    .images {
      margin-left: -15px;
      margin-right: -15px;
    }

    .image {
      position: relative;

      &:hover {
        .image-hover {
          opacity: 1;
        }
      }
    }

    .image-hover {
      display: flex;
      flex-direction: column-reverse;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      opacity: 0;
      will-change: opacity;
      transition: opacity 150ms ease-out;

      color: $white;
      text-align: center;
      font-size: 13px;
      line-height: 24px;
      font-weight: 500;
      padding-bottom: 13px;
    }

    .image-hover-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
    }

    .bricklayer-column-sizer {
      width: 25%;
    }

    .bricklayer-column {
      margin: 15px;
    }
  }
}
