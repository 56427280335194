// Header Overrides
h1, h2, h3, h4, h5, h6 {
  // margin-top: 2rem;
  font-family: $title-font-family;
}

// h2 {
h1 {
  color: $gray-3;
  font-size: 22px;
  font-weight: bold;
  line-height: 26px;
  letter-spacing: 1.38px;
  text-transform: uppercase;
}

// h1 {
h2 {
  // large, thin
  font-size: 28px;
  line-height: 42px;
  font-weight: 300;
  color: $gray-3;
  letter-spacing: 0.88px;
  text-transform: uppercase;
}

@media (min-width: $size-md) {
  h2 {
    font-size: 44px;
    letter-spacing: 1.38px;
  }
}


h3 {
  color: $gray-3;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@media (min-width: $size-md) {
  h3 {
    font-size: 18px;
  }
}

h4 {
  // brown, small, all caps
  color: $brown;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 2px;
}

// kind of a weird last minute change for the Resources pages
h5 {
  color: $gray-3;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}

h6 {
  font-size: 15px;
  line-height: 15px;
  font-weight: bold;
  letter-spacing: 1.3px;
  color: $gray-3;
  text-transform: uppercase;
}

a:focus {
  outline: none !important;
  box-shadow: none !important;
}

img {
  max-width: 100%;
}


// Codeblocks
pre code, pre.xdebug-var-dump{
  background: #fafafa;
  display: block;
  padding: 1rem !important;
  line-height: 1.5;
  color: inherit;
  border-radius: 2px;
  overflow-x: auto;
}

pre {
  code:not(.hljs) {
    background: #f8f8f8;
  }
}

// Icon Tweaks
i.fa {
  &.fa-heart, &.fa-heart-o {
    &.pulse {
      color: #920
    }
  }
}

.underlined {
  text-decoration: underline;
}

// Font Weights
b,
strong,
.bold {
  font-weight: 600;
}

.heavy {
  font-weight: 700;
}

.light {
  font-weight: 200;
}

// Colors
.text-light {
  color: rgba($light-color, 0.8);

  h1, h2, h3, h4, h5, h6 {
    color: rgba($light-color, 0.9);
  }
}

.red {
  color: red;
}

.well {
  padding: 20px;
  background-color: #fafafa;
  color: $gray-5;
}

// Error configuration
#error {
  text-align: center;
  position: relative;
  margin-top: 5rem;

  .icon {
    font-size: 50px;
  }
}

// Messages
#messages {
  margin-bottom: 1rem;

  .icon {
    font-size: 120%;
  }
}

// Notices
.notices {
  margin: 1.5rem 0;
  p {
    margin: 1rem 0;
  }
}

