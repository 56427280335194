.page-color {

  .color__collection,
  .color__title,
  .color__desc {
    text-align: center;
  }

  .color__title {
    margin-bottom: 5px;
    font-weight: 300;
    letter-spacing: 0.88px;
  }

  .color__desc {
    margin-bottom: 30px;
  }

  .specs {
    margin: 15px 0;
  }

  .spec {
    padding: 12px 0 8px;
    border-bottom: 1px solid #e0e0e0;
  }

  .spec__label {
    font-size: 13px;
    font-weight: 600;
    color: $gray-5;
    text-transform: uppercase;
  }

  .spec__value {
    font-size: 13px;
    color: $gray-5;
    margin-top: 3px;
  }

  .platforms-title {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 15px;
    color: $gray-5;
    text-transform: uppercase;
    margin-bottom: 17px;
  }

  // if we need to display this table anywhere else, make an include
  .mobile-platform-tile {
    border: 1px solid #CCCCCC;
    padding: 12px 13px;
    color: $gray-5;
    margin-bottom: 5px;

    // mod the grid
    .columns {
      margin-left: -10px;
      margin-right: -10px;
    }

    .column {
      padding-left: 10px;
      padding-right: 10px;
    }

    &__label {
      font-size: 11px;
      font-weight: 600;
      line-height: 13px;
      text-transform: uppercase;
      margin-bottom: 10px;
      letter-spacing: 1.38px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__value {
      font-size: 13px;
      line-height: 15px;
      margin-bottom: 7px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // TODO: move this to desktop only styles?
  .platforms-table {
    &__header-row {
      color: $white;
      background-color: #B3B3B3;
      display: flex;
      border: 1px solid #999999;
    }

    &__header-row-item {
      text-transform: uppercase;
      flex: 0 1 33%;
      text-align: center;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1.5px;
      padding: 8px 0;
      border-right: 1px solid #999999;

      &:last-child {
        border-right: 0;
      }
    }

    &__row {
      display: flex;
      border: 1px solid #CCCCCC;
      margin: 5px 0;
    }

    &__row-item {
      padding: 11px 0 13px;
      color: $gray-5;
      font-size: 14px;
      line-height: 16px;
      flex: 0 1 33%;
      text-align: center;
      border-right: 1px solid #e6e6e6;

      &:last-child {
        border-right: 0;
      }
    }
  }

  .downloads {
    margin-bottom: 10px;
  }

  .image-container {
    font-size: 0;
    line-height: 0;
    margin-bottom: 10px;
  }

  .image-caption {
    text-align: center;
    font-size: 13px;
    line-height: 15px;
  }

  // prob move this to desktop?
  .thumbs {
    display: flex;
    margin-top: 12px;
    margin-bottom: 30px;
    // modified grid
    margin-left: -4.5px;
    margin-right: -4.5px;

    &--centered {
      justify-content: center;
    }
  }

  .thumb {
    position: relative;
    padding-left: 4.5px;
    padding-right: 4.5px;
    margin-bottom: 9px;
    cursor: pointer;
    // kill white space under img
    font-size: 0;
    line-height: 0;

    &.active,
    &:hover {
      .thumb__border {
        opacity: 1;
      }
    }

    .thumb__border {
      opacity: 0;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 4.5px;
      width: calc(100% - 9px);
      height: 100%;
      border: 3px solid $black;
      transition: opacity 150ms ease-out;
    }
  }

  .all-colors-title {
    margin-top: 36px;
    margin-bottom: 25px;
  }
}

.color-subnav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span > img {
    // icons
    vertical-align: middle;
    padding-bottom: 3px;
  }

  .csb__all {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.color-download {
  display: block;
  position: relative;
  border: 1px solid #CCCCCC;
  padding: 13px 15px;
  margin-bottom: 10px;

  &:hover {
    text-decoration: none;
  }

  &__title {
    padding-right: 80px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: $gray-3;
    margin-bottom: 4px;
  }

  &__desc {
    padding-right: 80px;
    color: $gray-5;
    font-size: 13px;
    line-height: 15px;
  }

  &__type {
    position: absolute;
    right: 11px;
    top: 18px;
    text-align: center;
    width: 30px;
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    text-transform: uppercase;
    color: $gray-7;
    letter-spacing: 1.1px;

    img {
      display: block;
      opacity: 0.5;
      margin: 0 auto 8px;
    }
  }
}


@media (min-width: $size-sm) {
  .color-subnav-bar {
    .csb__mobile {
      display: none;
    }
  }
}


@media (min-width: $size-sm) {
  .page-color {
    &__upper-content {
      margin-bottom: 74px;
    }

    .color__collection,
    .color__title,
    .color__desc {
      text-align: left;
    }

    .color__title {
      margin-bottom: 20px;
      // this usually doesn't happen until size-md but we're doing this page differently
      font-size: 44px;
      line-height: 42px;
      letter-spacing: 1.38px;
    }

    .color__desc {
      margin-bottom: 33px;
    }

    .specs {
      margin-bottom: 25px;
    }

    .spec {
      padding-top: 10px;
    }

    .spec__label {
      font-size: 13px;
    }

    .spec__value {
      font-size: 15px;
      margin-top: 0;
    }

    .downloads {
      margin-bottom: 25px;
    }

    .image-container {
      margin-bottom: 12px;
      position: relative;
    }

    .image-gallery-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 36px;
      height: 36px;
      background-color: rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background-color 150ms ease-out;

      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    .all-colors-title {
      margin-top: 74px;
      margin-bottom: 35px;
    }
  }

  .color-subnav-bar {
    margin-bottom: 40px;
  }

  .color-download {
    min-height: 64px;
    padding: 14px 20px 13px;
    transition: border-color 150ms ease-out;

    &:hover {
      border-color: #808080;
    }

    &__title {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 2px;
    }

    &__desc {
      font-size: 14px;
      line-height: 16px;
    }

    &__type {
      top: 14px;
    }
  }
}
