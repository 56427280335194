.page-login {
  text-align: center;

  form {
    text-align: left;
  }

  button {
    display: block;
    margin: 0 auto 20px;
  }

  .login-links {

    a {
      display: block;
      text-decoration: underline;
      margin: 12px 0;
    }
  }
}


@media (min-width: $size-md) {
  .page-login {
    width: 393px;
    margin: 0 auto;

  }
}
