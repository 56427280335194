.modular-text-features {
  padding: 40px 0;

  .feature {
    margin: 10px 0 30px;

    &:last-child {
      margin-bottom: 0;
    }

    &__header {
      margin: 10px 0;
      font-size: 16px;
      line-height: 19px;
      font-weight: bold;
      color: $gray-3;
    }
  }
}


@media (min-width: $size-md) {
  .modular-text-features {
    padding: 80px 0;
  }
}
