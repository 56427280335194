form {
  .button-wrapper {
    margin-top: 0.75rem;
    margin-bottom: 1rem;
  }

  span.required {
    color: $error-color;
    font-weight: 700;
    font-size: 150%;
  }

  .form-input[type=range] {
    appearance: slider-horizontal;
    &:focus {
      box-shadow: none;
      border: none;
    }
  }

  .form-input {
    border: 0;
  }
}

#grav-login {

  > form {
    margin: 2rem auto 0;
    max-width: 350px;
  }
  .form-label {
    display: none;
  }
  .form-data {
    margin: 1rem 0;
  }
  .form-input {
    text-align: center;
  }
  .button-wrapper {
    text-align: right;

    .form-data.rememberme {
      margin: 0;
      float: left;
    }
    button[type="submit"] {
      @include button-primary;
    }
  }
}
