.page-home {
  .home__headline {
    margin: 0 auto;
    text-align: center;
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
    color: $gray-3;
    letter-spacing: -0.34px;
    margin-bottom: 30px;
  }

  .home__hero {
    position: relative;
    text-align: center;
    margin: 20px 0 40px;

    &-unit:not(:first-child) {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }

    &-caption {
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      background-color: $white;
    }
  }

  .home__tout-headline {
    text-align: center;
    margin-bottom: 36px;
  }

  .home__tout {
    text-align: center;
    margin-bottom: 40px;

    &-title {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 12px;
      color: $gray-3;
      font-weight: bold;
      margin: 14px 0 12px;
    }

    &-cta {
      display: flex;
      margin: 18px 0 0;
    }
  }
}

@media (min-width: $size-md) {
  .page-home {
    .home__headline {
      font-size: 30px;
      line-height: 42px;
      letter-spacing: -0.47px;
      margin-top: 25px;
      margin-bottom: 45px;
    }

    .home__tout-headline {
      margin-bottom: 60px;
    }

    .home__touts {
      margin-bottom: 80px;
    }

    .home__tout {
      margin-bottom: 0;

      &-cta {
        display: inline-flex;
      }
    }
  }
}
