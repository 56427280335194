// Extends spectre _tabs.scss

.tab {
  border-bottom: 1px solid #ccc;

  .tab-item {
    border: 1px solid #CCC;
    border-bottom: 0;
    text-transform: uppercase;
    margin-right: 11px;
    position: relative;
    text-align: center;
    padding-left: 0;
    background: none;

    // NOTE: will only work for exactly 2 tabs
    width: calc(50% - 5.5px);

    &.active {
      border-bottom: 1px solid #ccc;

      // cover up the .tab border
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: -2px;
        left: 0px;
        width: 100%;
        height: 2px;
        background-color: $white;
      }

      a {
        border-bottom: 0;
        color: $gray-3;
        padding-bottom: 16px;
      }
    }

    &:first-child {
      &.active:after {
        left: -1px;
        width: calc(100% + 1px);
      }
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      margin: 0;
      padding: 0;
      font-size: 13px;
      font-weight: bold;
      line-height: 15px;
      letter-spacing: 1.3px;
      color: $gray-5;
      padding: 17px 0 15px;
    }
  }
}


.tab-content {
  display: none;
  padding: 30px 0 40px;

  &.active {
    display: block;
  }
}

@media (min-width: $size-md) {
  .tab {
    .tab-item {
      width: auto;

      a {
        padding-left: 28px;
        padding-right: 27px;
      }
    }
  }
}
