html {
  height: 100%;
}

#body-wrapper {
  // .container {
  //   padding: $vert-padding;
  // }

  // Fixed Header solution
  .header-fixed & {
    padding-top: $header-height-large;
  }
}

.header-fixed {
  .hero + #start > #body-wrapper {
    padding-top: 0;
  }
}

section.section {
  // padding-left: $horiz-padding;
  // padding-right: $horiz-padding;
  position: relative;
}

.overlay-light, .overlay-dark, .overlay-light-gradient, .overlay-dark-gradient {
  z-index: 0;
}

// Hero
.hero {
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 6rem;
  padding-bottom: 7rem;
  background-size: cover;
  background-position: center;

  &.hero-fullscreen {
    height: 100vh;
  }

  &.hero-large {
    height: 500px;
  }

  &.hero-medium {
    height: 400px;
  }

  &.hero-small {
    height: 110px;
  }

  &.hero-tiny {
    height: 8rem;
  }

  .header-fixed & {
    background-position: 50% 0;
  }

  //&.parallax {
  //  background-attachment: fixed;
  //}

  h1 {
    color: $header-text-light;

    font-size: 26px;
    line-height: 34px;

    @media (min-width: $size-sm) {
      font-size: 34px;
      line-height: 46px;
    }

    @media (min-width: $size-md) {
      font-size: 60px;
      line-height: 72px;
    }
  }

  h2 {
    color: rgba($header-text-dark, 0.8);
    font-size: 2.5rem !important;
  }

  h1 + h2 {
    margin-top: -45px !important;
  }

  &.text-light {
    h1 {
      color: $header-text-light;
    }
    h2 {
      color: rgba($header-text-light, 0.8);
    }
  }

  p {
    font-size: 130%;
    font-weight: 300;
  }
}

// Overlay
.image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;

  .overlay-light & {
    background: rgba(#fff, 0.4);
  }

  .overlay-light-gradient & {
    background: linear-gradient(to bottom, rgba(#fff,0.5), rgba(#fff,0.2));
  }

  .overlay-dark & {
    background: rgba(#000, 0.4);
  }

  .overlay-dark-gradient & {
    background: linear-gradient(to bottom, rgba(#000,0.5), rgba(#000,0.2));
  }
}

.back-to-top {
  display: none;

  &.active {
    display: block;
  }

  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 44px;
  height: 44px;
  background-color: $black;
  cursor: pointer;

  &:after {
    content: '\f106';
    font-family: 'FontAwesome';
    font-size: 12px;
    font-weight: bold;
    color: $white;
    position: absolute;
    top: 10px;
    left: 16px;
  }
}









