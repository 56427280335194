.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  // mobile defaults to full width
  width: 100%;
  height: 40px;
  padding: 0 30px;
  border-radius: 20px;
  border: 2px solid $brown;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  color: $gray-5;
  background-color: transparent;

  // will this work?
  margin: 40px auto;

  &:hover {
    background-color: $brown;
    border-color: $brown;
    color: $white;
  }

  &:focus {
    background-color: transparent;
    border-color: $brown;
    color: $gray-5;
  }
}

@media (min-width: $size-md) {
  .btn {
    width: auto;

    &--full-width {
      width: 100%;
      padding: 0;
    }
  }
}
