.products__title {
  text-align: center;
  margin-bottom: 15px;
}

.desktop-product-list {
  display: none;
}

.product-list-item {
  padding: 40px 0 0;

  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }

  h4 {
    margin-top: 3px;
  }

  .pli__color-count {
    color: $brown;
    text-transform: uppercase;
  }
}


@media (min-width: $size-sm) {
  .products__title {
    margin-bottom: 10px;
  }

  .product-list-item {
    padding: 50px 0;

    h4 {
      margin-top: 6px;
    }
  }
}

@media (min-width: $size-md) {
  .mobile-product-menu {
    display: none;
  }

  .desktop-product-list {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      font-size: 13px;
      letter-spacing: 1.3px;
      font-weight: 600;
      margin: 0 15px;
      padding: 10px 0;
      color: $gray-5;
      text-transform: uppercase;

      &:hover {
        color: $black;
      }
    }
  }
}
