// I hate this - totally goes against mobile-first.
// Should get rid of this file.

.mobile-container {
  position: absolute;
  //width: 100%;
  //height: 100%;
  top: 40%;
  left: 0;
  margin:  0 auto;
  z-index: 2;
}

// Hamburger Menu
.mobile-menu {
  display: none;
  top: 0;
  right: 0;
  z-index: 3;

  @include breakpoint(md) {
    display: block;
  }

  .menu-btn {
    position: absolute;
    top: 10px;
    right: 11px;
    padding: 10px;
    cursor: pointer;
    z-index: 100;
    transition: opacity .25s ease, top 0.5s ease;

    &:hover {
      opacity: .7;
    }

    i.icon-cross {
      display: none;
      margin-top: 3px;
    }

    &.active {
      i.icon-menu {
        display: none;
      }

      i.icon-cross {
        display: block;
      }
    }

    i {
      font-size: 20px;
      color: $black;
    }
  }
}

.overlay {
  position: fixed;
  background: #000;
  top: $header-height-small;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, height .35s;

  &.open {
    opacity: 1;
    visibility: visible;
    height: 100%;
  }
}

.overlay-menu {
  position: relative;
  height: 100%;
  overflow-y: scroll;
  margin: 0 auto;
  padding: 24px 20px 38px;
  color: $white;

  a {
    color: $white;
    display: block;
  }

  ul {
    list-style: none;
    text-transform: uppercase;
    margin: 0;
  }

  li {
    // undo normal list styles
    padding: 0;
    background: none;
  }

  & > ul {
    // primary items
    & > li {
      font-size: 16px;
      font-weight: bold;
      margin-top: 0;
      letter-spacing: 1px;
      border-bottom: 1px solid #1A1A1A;


      &:last-child {
        border-bottom: 0;
      }

      & > a {
        padding-top: 16px;
        padding-bottom: 16px;
      }

      &:first-child > a {
        padding-top: 0;
        // NOTE: this only works because we know the first item has a sub-menu
        padding-bottom: 0;
      }
    }

    // secondary items
    ul {
      font-size: 14px;
      font-weight: normal;
      // indent it
      margin: 12px 0 20px 20px;

      & > li {
        margin-top: 0;
        line-height: 40px;
        letter-spacing: 1px;
        font-weight: 600;
      }
    }
  }

  .nav-supplemental {
    float: right;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: normal;
    text-transform: none;
  }
}



body.mobile-nav-open {
  overflow-y: hidden;
  height: 100%;
}
