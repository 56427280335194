// Core variables and mixins
@import 'spectre/variables';
@import 'spectre/mixins';

@import 'theme/variables';
@import 'theme/mixins';

@import 'theme/layout';

@import 'theme/base';

@import 'theme/framework';
@import 'theme/buttons';
@import 'theme/typography';
@import 'theme/forms';
@import 'theme/mobile';
@import 'theme/animation';
@import 'theme/tabs';

@import 'theme/header';
@import 'theme/footer';
@import 'theme/menu';

// Extra Skeleton Styling
@import 'theme/blog';
@import 'theme/onepage';

@import 'theme/material-forms';

@import 'theme/utilities';

// pages
@import 'theme/pages/home';
@import 'theme/pages/login';
@import 'theme/pages/products';
@import 'theme/pages/color';  // otherwise known as the PDP
@import 'theme/pages/gallery';

// modular
@import 'theme/modular/image-features';
@import 'theme/modular/text-features';
@import 'theme/modular/contact-form';

// partials ... maybe this should go under /theme as well?
@import 'partials/color-list-items';
