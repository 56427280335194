// modify the spectre column rules
.columns {
  margin-left: -15px;
  margin-right: -15px;
}

.column {
  padding-left: 15px;
  padding-right: 15px;
}
