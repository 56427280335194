
$white: #fff;
$lt-gray: #F7F7F7;
$lt-gray-2: #E0E0E0;
$md-gray: #555;

$gray-3: #333;
$gray-5: #555;
$gray-7: #777;

$black: #000;

$dark-blue: #0F253A;
$off-white: #C0C6CC;
$brown: #A09274;


// Layout
$horiz-padding: 1rem;
$vert-padding: 2rem 0 2rem;

// Fonts
$title-font-family: $base-font-family, $fallback-font-family !default;

// Header
$header-height-large: 78px;
$header-height-small: 60px;
$header-color-dark: #222;
$header-text-light: #FFF;
$header-text-dark: darken($dark-color, 15%);

// Dropdown Menu
$dropmenu-bg: $white;
$dropmenu-hover-text: $black;
$dropmenu-horiz-padding: 24px;
$dropmenu-vert-padding: 6px;
$dropmenu-child-padding: 10px;
$dropmenu-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

// Mobile Menu
$mobile-color-main: $primary-color;
$mobile-color-active: #FFF;
$mobile-color-link: #FFF;
