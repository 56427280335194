.dropmenu {

  @include breakpoint(md) {
    display: none;
  }

  ul {
    white-space: nowrap;
    margin: 0;
    display: flex;

    li {
      position: relative;
      margin: 0;
      padding: 0;
      background: none;

      &:hover {
        & > ul {
          display: block;
          visibility: visible;
        }
      }

      a {
        color: $md-gray;
        text-decoration: none;
        padding: $dropmenu-vert-padding 0 $dropmenu-vert-padding;
        margin-left: 40px;
        display: block;
        font-weight: 500;

        &:hover, &:focus, &.active {
          color: $dropmenu-hover-text;
          text-decoration: underline;
        }

        // down caret
        &:before {
          content: '';
          width: 8px;
          height: 4px;
          background: url('../images/icon-dropdown-arrow.svg') 0 0 no-repeat;
          background-size: 75%;
          display: inline-block;
          float: right;
          margin-top: 7px;
          margin-left: 5px;
        }

        &:only-child {
          // any main menu items that don't have a dropdown
          &:before {
            display: none;
          }
        }
      }

      &:first-child a {
        margin-left: 0;
      }

      // submenu dropdown
      ul li a:only-child {
        padding-left: $dropmenu-horiz-padding;
        padding-right: 48px;
        margin-left: 0;
      }
    }

    ul li a:before {
      background: none;
    }

    // dropdown
    ul {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 50%;
      list-style: none;
      background: $dropmenu-bg;
      visibility: hidden;
      padding: 10px 0;
      border: 1px solid #e6e6e6;
    }
  }

  & > ul > li {
    display: inline-block;
  }

  // main items
  & > ul > li > a {
    font-size: 13px;
    line-height: 17px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    color: #333;
    border-bottom: 2px solid transparent;
    padding-bottom: 2px;
    transition: border 150ms ease-out;

    &:hover, &.active {
      text-decoration: none;
      border-bottom-color: #CFC8B9;
    }

    &:focus {
      text-decoration: none;
    }
  }

  // Animation options
  &.animated {
    ul li {
      transition: background .7s, color 0.5s;
    }

    ul li:hover > ul {
      opacity: 1;
      transform: translate(-50%, 0);
    }

    ul ul {
      transition: transform .3s, opacity .5s;
      opacity: 0;
      transform: translate(-50%, -10px);
    }
  }
}
